import React from "react"
import PropTypes from "prop-types"
import { BLUE } from "../constants"

const Section = ({ children, title, border, className }) => {
  return (
    <section
      id={title.replace(/\s/g, "-").toLowerCase()}
      style={{
        marginTop: "10px",
        paddingBottom: "10px",
        borderBottom: border ? "solid black 2px" : "none"
      }}
      className={className}
    >
      <h4
        style={{
          color: BLUE,
          fontWeight: "bold",
        }}
      >
        {title}
      </h4>
      <div style={{marginLeft: "10px", marginRight: "10px"}}>
      {children}
      </div>
    </section>
  )
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  border: PropTypes.bool
}

Section.defaultProps = {
  border: true
}

export default Section
