import React from "react"
import Layout from "../components/layout"
import { GatsbyImage } from "gatsby-plugin-image";
import Seo from "../components/seo"
import Section from "../components/section"
import { Row, Col, Button } from "react-bootstrap"
import { LEARNMORESTYLE } from "../constants"
import { jumptarget, serviceRow, serviceCol, groupPhotoHolder, groupPhoto} from "./index.module.css"
import { graphql } from "gatsby"

const IndexPage = ({ data }) => (
  <Layout>
    <Seo title="Home" />
    {/* TODO: Implement Deals */}
    {/* <Section title="Current Deals" border={false}>
      <Image
        fluid
        src="http://placehold.jp/902x315.png"
      />
    </Section> */}
    {/* TODO: Work on email sign up for deals. MailChimp? */}
    {/* <Section title="Sign Up for More Deals">
      <Form className={signupForm}>
        <Row>
          <Col md={8}>
            <Form.Group>
              <Form.Control type="email" placeholder="Your email address" />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Button style={LEARNMORESTYLE}> Sign Up</Button>
          </Col>
        </Row>
      </Form>
    </Section> */}
    <Section title="What We Do" className={jumptarget}>
      <Row className={serviceRow}>
        <Col md={4} className={serviceCol}>
          <h5>Residential Construction</h5>
          <p>
            We work with some of the largest builders in the Atlanta area to deliver
            quality homes. Our experience ranges from full production to custom home builders,
            we have the necessary experience to complete your project
            on time. We are routinely top ranked for our warranty resolution.
          </p>
          <Button size="lg" style={LEARNMORESTYLE} href="/services/residential">
            Learn More
          </Button>
        </Col>
        <Col md={4} className={serviceCol}>
          <h5>Residential & Commercial Service</h5>
          <p>
            Whether you’re a homeowner that wants a ceiling fan installed or a
            large business needing some new lighting, our service department
            handles it all. Simply give us a call and we will get right to
            making your project a reality
          </p>
          <Button size="lg" style={LEARNMORESTYLE} href="/services/service">
            Learn More
          </Button>
        </Col>
        <Col md={4} className={serviceCol}>
          <h5>Commercial Lighting</h5>
          <p>
            From small expansions to full new construction, we have done it all.
            We have completed jobs on top of skyscrapers, in the basements of
            military installations, and everywhere in between. Whatever the
            scope and size of your project, we can make it happen.
          </p>
          <Button size="lg" style={LEARNMORESTYLE} href="/services/commercial">
            Learn More
          </Button>
        </Col>
        
      </Row>
    </Section>
    <div className={groupPhotoHolder}>
      {/* TODO: Update group photo */}
      <GatsbyImage className={groupPhoto} image={data.image1.childImageSharp.gatsbyImageData} />
    </div>
    <Section title="About Us" className={jumptarget}>
      <p>
        Doctor Electric is a veteran owned business. The company began in the fall of 1997 with 
        one service truck running service calls. Over time Doctor Electric grew with the addition
        of service trucks and personnel. We began to serve more residential
        service calls, but also started new residential construction and
        commercial work as well. Due to the high quality of work, spread mostly
        through our customer referrals, we now provide a wide range of high quality
        electrical services from new residential construction and residential
        service, to commercial and government jobs of tremendous scale. Our
        clients now include various agencies and departments of federal, state,
        and municipal governments and corporations ranging from tens of
        employees to thousands, but our first focus has always been to deliver
        high quality products and services to our customers at a fair price, no
        matter how big or small.
      </p>
    </Section>
    {/* TODO: Need to work on  job list integration*/}
    {/* <Section title="Careers" className={jumptarget} border={false}>
      <p>
        We’re always looking for people to join our team to assist us in
        delivering the best electrical service in the Atlanta area. See our
        current job postings below for our current openings or drop your resume
        to resume@doctorelectric.net.
      </p>
      <CareerList />
    </Section> */}
  </Layout>
)

export default IndexPage

export const IndexQuery = graphql`query IndexQuery {
  image1: file(relativePath: {eq: "DrElectricTruck.png"}) {
    childImageSharp {
      gatsbyImageData(layout: CONSTRAINED)
    }
  }
}
`
